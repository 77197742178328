/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './globalStyle';
import { defaultRebootTheme } from 'styled-reboot';

const Layout = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <ThemeProvider theme={defaultRebootTheme}>
        <GlobalStyle />
        <div
          className={className}
          style={{
            margin: `0 auto`,
            padding: 0,
            width: `100%`,
            height: `100%`,
          }}
        >
          {children}{' '}
        </div>{' '}
      </ThemeProvider>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
