import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { reboot } from 'styled-reboot';

const GlobalStyle = createGlobalStyle`
    ${reboot}
    /* other styles */

    html,
    body,
    body > div,
    body > div > div {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    min-height: 100%;
    }

    html {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 100%;
    }

    @media (min-width: 768px) {
    html {
        /* 18 pixels in most browsers */
        font-size: 112.5%;
    }
    }

    /* improve font rendering */
    a,
    p,
    h2,
    input,
    div {
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    -webkit-font-smoothing: auto;
    /* Chrome, Safari */
    -moz-osx-font-smoothing: auto;
    /* Firefox */
    }

    .error section::after {
    /* background-color: white; */
    background-color: #4c3f2f;
    background-blend-mode: luminosity;
    }

    section {
    color: #e6e7e8;
    text-shadow: 2px 2px #333;
    line-height: 1.2rem;
    text-align: center;
    }

    article {
    padding: 5vh;
    max-width: 650px;
    }

    h1 {
    font-size: 2.2rem;
    color: #e6e7e8;
    text-shadow: 2px 2px #333;
    margin: 0 0 0.35em 0;
    padding-top: 10%;
    text-decoration: underline;
    }

    @media (max-width: 550px) {
    h1 {
        line-height: 2.7rem;
    }
    }

    footer {
    position: absolute;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    height: 1em;
    left: 0;
    bottom: 3vh;
    clear: both;
    }

    a,
    a:hover,
    a:active {
    color: #e6e7e8;
    border-bottom: 1px solid #e6e7e8;
    }

`;

export default GlobalStyle;
