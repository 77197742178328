import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Image from 'gatsby-image';

const BackgroundImage = ({ className, style }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "background.png" }) {
          childImageSharp {
            fluid(
              quality: 80
              srcSetBreakpoints: [200, 509, 726, 885, 1027, 1153, 1267]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      const styling = {
        ...style,
        ...{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        },
      };

      return (
        <Image
          className={className}
          fluid={data.placeholderImage.childImageSharp.fluid}
          durationFadeIn={300}
          style={styling}
        />
      );
    }}
  />
);

export default BackgroundImage;
