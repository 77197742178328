import { Link } from 'gatsby';
// import PropTypes from "prop-types"
import React from 'react';

const Footer = () => (
  <footer>
    Check mijn{' '}
    <a href="mailto:contact@joeyvandijk.nl" rel="noopener noreferrer">
      vader
    </a>{' '}
    voor informatie.
  </footer>
);

// Footer.propTypes = {
// //   siteTitle: PropTypes.string,
// }

// Footer.defaultProps = {
// //   siteTitle: ``,
// }

export default Footer;
